define("discourse/plugins/discourse-encrypt/discourse/components/encrypt-preferences-dropdown", ["exports", "I18n", "select-kit/components/dropdown-select-box"], function (_exports, _I18n, _dropdownSelectBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _dropdownSelectBox.default.extend({
    classNames: ["encrypt-preferences-dropdown"],
    selectKitOptions: {
      icon: "wrench",
      showFullTitle: false
    },
    content: [{
      id: "export",
      icon: "file-export",
      name: _I18n.default.t("encrypt.export.title")
    }, {
      id: "managePaperKeys",
      icon: "ticket-alt",
      name: _I18n.default.t("encrypt.manage_paper_keys.title")
    }, {
      id: "decryptAll",
      icon: "unlock",
      name: _I18n.default.t("encrypt.decrypt_all.button")
    }, {
      id: "rotate",
      icon: "sync",
      name: _I18n.default.t("encrypt.rotate.title")
    }, {
      id: "reset",
      icon: "trash-alt",
      name: _I18n.default.t("encrypt.reset.title")
    }]
  });
});