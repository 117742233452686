define("discourse/plugins/discourse-encrypt/lib/permanent-topic-decrypter", ["exports", "@glimmer/tracking", "pretty-text/upload-short-url", "discourse/lib/ajax", "discourse/plugins/discourse-encrypt/lib/base64", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/protocol", "discourse/plugins/discourse-encrypt/lib/uploads"], function (_exports, _tracking, _uploadShortUrl, _ajax, _base, _discourse, _protocol, _uploads) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UPLOAD_REGEX = /\[([^\]]+)\]\((upload:\/\/\w{27}.encrypted)\)/g;
  class PermanentTopicDecrypter {
    static #_ = (() => dt7948.g(this.prototype, "logContent", [_tracking.tracked], function () {
      return "";
    }))();
    #logContent = (() => (dt7948.i(this, "logContent"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "success", [_tracking.tracked], function () {
      return false;
    }))();
    #success = (() => (dt7948.i(this, "success"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "error", [_tracking.tracked], function () {
      return false;
    }))();
    #error = (() => (dt7948.i(this, "error"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "running", [_tracking.tracked], function () {
      return false;
    }))();
    #running = (() => (dt7948.i(this, "running"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "topicTitle", [_tracking.tracked]))();
    #topicTitle = (() => (dt7948.i(this, "topicTitle"), void 0))();
    topicId;
    constructor(topicId) {
      this.topicId = topicId;
    }
    log(msg) {
      this.logContent += `${msg}\n`;
    }
    async retryOnRateLimit(callback) {
      let attemptNumber = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      try {
        return await callback();
      } catch (e) {
        if (e.jqXHR && e.jqXHR.status === 429 && attemptNumber < 3) {
          const retryAfterHeader = e.jqXHR.getResponseHeader("Retry-After");
          const retryAfter = retryAfterHeader ? parseInt(retryAfterHeader, 10) : 10;
          this.log(`Rate limited (attempt: ${attemptNumber}), retrying in ${retryAfter} seconds...`);
          await new Promise(resolve => setTimeout(resolve, retryAfter * 1000));
          return this.retryOnRateLimit(callback, attemptNumber + 1);
        }
        throw e;
      }
    }
    async run() {
      try {
        this.running = true;
        this.log("Starting decryption...");
        const topicId = this.topicId;
        this.log("Fetching raw topic data...");
        const encryptedData = await this.retryOnRateLimit(() => (0, _ajax.ajax)(`/encrypt/data_for_decryption.json?topic_id=${topicId}`));
        this.log("Loading topic encrypting key...");
        const topicKey = await (0, _discourse.getTopicKey)(topicId);
        this.log("Decrypting title...");
        const decryptedTitle = (await (0, _protocol.decrypt)(topicKey, encryptedData.title)).raw;
        this.topicTitle = decryptedTitle;
        const decryptedPosts = {};
        const decryptedPostPromises = [];
        this.log("Queuing posts for decryption...");
        for (const [id, post] of Object.entries(encryptedData.posts)) {
          const promise = (0, _protocol.decrypt)(topicKey, post).then(decryptedPost => decryptedPosts[id] = decryptedPost.raw).catch(error => {
            throw new Error(`Unable to decrypt post ${id}: ${error}`);
          });
          decryptedPostPromises.push(promise);
        }
        this.log("Waiting for posts to decrypt...");
        await Promise.all(decryptedPostPromises);
        this.log("Checking for encrypted uploads...");
        const shortUrls = [];
        for (let [, post] of Object.entries(decryptedPosts)) {
          for (const [,, shortUrl] of [...post.matchAll(UPLOAD_REGEX)]) {
            shortUrls.push(shortUrl);
          }
        }
        if (shortUrls.length > 0) {
          this.log(`Fetching full URLs for ${shortUrls.length} uploads...`);
          await this.retryOnRateLimit(() => (0, _uploadShortUrl.lookupUncachedUploadUrls)(shortUrls, _ajax.ajax));
        }
        for (let [id, post] of Object.entries(decryptedPosts)) {
          for (const [, rawMetadata, shortUrl] of [...post.matchAll(UPLOAD_REGEX)]) {
            this.log(`  Found ${shortUrl} in post ${id}...`);
            const metadata = rawMetadata.split("|");
            const type = metadata.find(m => m.startsWith("type="))?.split("=")?.[1];
            if (!type) {
              throw new Error(`Could not determine type of upload ${shortUrl}`);
            }
            const key = metadata.find(m => m.startsWith("key="))?.split("=")?.[1];
            if (!key) {
              throw new Error(`Could not determine key of upload ${shortUrl}`);
            }
            this.log("  Looking up full upload URL...");
            const urlData = await (0, _uploadShortUrl.lookupCachedUploadUrl)(shortUrl);
            const url = urlData.short_path;
            if (!url) {
              throw new Error(`Could not find full URL for upload ${shortUrl}`);
            }
            const keyPromise = new Promise((resolve, reject) => {
              window.crypto.subtle.importKey("raw", (0, _base.base64ToBuffer)(key), {
                name: "AES-GCM",
                length: 256
              }, true, ["encrypt", "decrypt"]).then(resolve, reject);
            });
            this.log(`    Downloading and decrypting ${shortUrl}...`);
            const decryptedDownloadedFile = await this.retryOnRateLimit(() => (0, _uploads.downloadEncryptedFile)(url, keyPromise, {
              type
            }));
            this.log(`    Getting original filename of ${shortUrl}`);
            const uploadMeta = await this.retryOnRateLimit(() => (0, _ajax.ajax)(`/uploads/lookup-metadata.json`, {
              data: {
                url: urlData.url
              },
              type: "POST"
            }));
            const originalFilename = uploadMeta.original_filename.replace(".encrypted", "");
            this.log(`    Original filename is ${originalFilename}`);
            if (!originalFilename.split(".").filter(Boolean).length > 1) {
              throw new Error("Original filename has no extension. Aborting.");
            }
            this.log(`    Re-uploading ${shortUrl}...`);
            const newShortUrl = await this.retryOnRateLimit(() => this.uploadBlob(decryptedDownloadedFile.blob, originalFilename));
            this.log(`    Uploaded as ${newShortUrl}.`);
            post = post.replace(shortUrl, newShortUrl);
          }
          decryptedPosts[id] = post;
        }
        this.log("Updating topic with decrypted data...");
        await this.retryOnRateLimit(() => (0, _ajax.ajax)("/encrypt/complete_decryption.json", {
          type: "POST",
          data: {
            topic_id: topicId,
            title: decryptedTitle,
            posts: decryptedPosts
          }
        }));
        this.log("Done!");
        this.success = true;
        return true;
      } catch (e) {
        this.log(`Error: ${e}`);
        this.error = true;
        throw e;
      } finally {
        this.running = false;
      }
    }
    async uploadBlob(blob, filename) {
      const formData = new FormData();
      formData.append("files[]", blob, filename);
      formData.append("upload_type", "composer");
      const result = await (0, _ajax.ajax)("/uploads.json", {
        type: "POST",
        data: formData,
        processData: false,
        contentType: false
      });
      return result.short_url;
    }
  }
  _exports.default = PermanentTopicDecrypter;
});